import React from "react"
import '../css/header.css';
import { Link } from "gatsby";



const NavToggleClick =()=>{
    let mainNav = document.getElementById("js-menu");
    mainNav.classList.toggle('active');
}



const Header = () => {
    return (
       
          <nav className="navbar">
            <span className="navbar-toggle" id="js-navbar-toggle" 
              onClick={NavToggleClick}>
                <i className="fa fa-bars"></i>
            </span>
            <a href="http://eruditescholars.net" className="logo">Erudite Scholars</a>
            <ul className="main-nav" id="js-menu">
              <li>
                  <Link to="/" className="nav-links">
                     Home
                  </Link>
                 
              </li>

            </ul>
          </nav>
     
    )
}

export default Header;
