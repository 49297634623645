import React from 'react';
import {Link} from 'gatsby';
import Header from './Header';
import '../css/layout.css';





const Layout = (props) => {
    return (
        <div>

           <Header />

          
               <div className="row">
                    <div className="main">
                       {props.children}
                    </div>

                    <div className="side">
                        <p>Hello from space jam</p>
                    </div>
               </div>
        </div>

    );
}

export default Layout;