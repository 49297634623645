import React from "react"
import Layout from '../components/Layout';
import { graphql, Link } from "gatsby";
import Img from 'gatsby-image';
import '../css/link.css';
import '../css/index.css';
import '../css/button.css';





const IndexQuery = ({data:{HomePageQuery, imageOne}})=> {
    const {edges} = HomePageQuery;
    return(
        <div>
           <Layout>
            {/*<div style={{width: '50%'}}>
                <Img fluid={imageOne.childImageSharp.fluid} />
            </div>*/}
           
           {edges.map(({node:{excerpt,frontmatter:{title, path, date }}})=>{
               return(
                <div key={title}>
                  <div className="blogTitle">
                       <h1 className="postTitle">
                            <Link to={path}>
                                    {title} 
                            </Link>
                       </h1>
                       <div className="excerpt">{excerpt}</div>
                       
                       <p>{date}</p>


                       <Link to={path} className="btn btn-default">
                           Read Article
                        </Link>

                        
                       
                  </div>

                  <div>

                  </div>

                </div>
                
               )
           })}

           </Layout>
           
        </div>
    )
}

export const query = graphql`
        query {
            HomePageQuery:
                allMarkdownRemark(
                    sort:{order:DESC, fields:[frontmatter___date]}
                ){
                    edges{
                    node{
                        excerpt(pruneLength: 250)
                        frontmatter{
                        date(formatString: "MMMM DD, YYYY")
                        title
                        path
                        tags
                      }
                    }
                }
           },
            imageOne: file(relativePath: { eq: "image1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
                }
            }
            }
  }

`

export default IndexQuery;